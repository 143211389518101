import React, { FC } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { formatDateInTimeZone } from '../../../../../utils/helpers'
// import { sortAllergiesByName } from '../../../../utils/fhirUtils/sortAllergies
import { handleCodeableConcept } from '../../../../../utils/helpers'
import PaginationButtons from '../../../../PaginationButtons'

interface Props {
    allergyData: any;
    patientData: any;
    isFetching: boolean;
    handleChangePage: (url: string) => void;
}

const AllergyList: FC<Props> = ({ allergyData, patientData, handleChangePage, isFetching }: Props) => {
    const patientAllergies = allergyData && allergyData.entry ? allergyData.entry.map((allergy: any) => allergy.resource) : []

    const nextLink = allergyData && allergyData.link.find((l: any) => l.relation === 'next')
    const previousLink = allergyData && allergyData.link.find((l: any) => l.relation === 'previous')

    
    return (
        <>
            <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
                {patientAllergies.length > 0 &&
                    <div className='mb-2'>
                        <PaginationButtons
                            backDisabled={!previousLink}
                            nextDisabled={!nextLink}
                            onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                            onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                        />
                    </div>
                }
                {allergyData?.total > 0 ? `${allergyData.total.toLocaleString()} record(s) found` : ''}
            </div>
            <Table size='sm' hover>
                <thead>
                    <tr>
                        <th className='d-table-cell'>Code</th>
                        <th className='d-none d-lg-table-cell'>Type</th>
                        {/* <th scope='col'>Criticality</th> */}
                        {/* <th scope='col'>Onset</th> */}
                        {/* <th scope='col'>Last Occurence</th> */}
                        <th className='d-none d-lg-table-cell'>Recorded Date</th>
                        <th className='d-none d-lg-table-cell'>View Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        isFetching ? (
                            <tr>
                                <td colSpan={6} rowSpan={15} className='d-flex'>
                                    <Spinner
                                        as='span'
                                        animation='border'
                                        role='status'
                                        aria-hidden='true'
                                    />
                                    Loading Member Allergy Intolerances...
                                </td>
                            </tr>
                        ) : allergyData?.total === 0 ? (

                            <tr>
                                <td colSpan={6} rowSpan={15} className='text-center'>
                                    No Allergy Intolerances found for this member.
                                </td>
                            </tr>
                        ) : (
                            <>
                                {patientAllergies.map((allergy: any) => (
                                    <tr key={allergy.id}>
                                        <td className='d-table-cell'><Link to={`/patient/AllergyIntolerance/${allergy.id}`}>{handleCodeableConcept(allergy?.code)}</Link></td>
                                        <td className='d-none d-lg-table-cell'>
                                            {allergy?.type ? allergy.type.charAt(0).toUpperCase() + allergy.type.slice(1) : ''}
                                        </td>
                                        <td className='d-none d-lg-table-cell'>{allergy?.recordedDate ? formatDateInTimeZone(allergy?.recordedDate, 'MM/dd/yyyy', 'UTC') : ''}</td>
                                        <td className='d-none d-lg-table-cell'><Link to={`/patient/AllergyIntolerance/${allergy.id}`}>View Detail</Link></td>
                                    </tr>
                                ))}
                            </>
                        )
                    }
                </tbody>
                <tfoot>
                    {patientAllergies.length > 0 &&
                        <tr>
                            <td scope="row" colSpan={5}>
                                <PaginationButtons
                                    backDisabled={!previousLink}
                                    nextDisabled={!nextLink}
                                    onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                                    onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                                />
                            </td>
                        </tr>
                    }
                </tfoot>
            </Table >
        </>
    )
}



export default AllergyList