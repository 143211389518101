import React, { useEffect } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import useUser from '../../hooks/authentication/useUser'
import Loader from '../../components/Loader'
import useUserAuthenticated from '../../hooks/authentication/useUserAuthenticated'


const isAuthenticated = (ComposedComponent: React.ComponentType<any>): React.ComponentType<any> => {
  const Authenticated = (props: any) => {
    const { data: authenticated, refetch: checkIfAuthenticated, isError } = useUserAuthenticated()
    const { isStale, refetch: refetchUser, status } = useUser()

    useEffect(() => {
      const handleFocus = () => {
        checkIfAuthenticated()
      }

      checkIfAuthenticated()

      window.addEventListener('focus', handleFocus)

      return () => {
        window.removeEventListener('focus', handleFocus)
      }
    }, [])

    useEffect(() => {
      if (authenticated) {
        if (authenticated.status && isStale) {
          refetchUser()
        }
      }
    }, [authenticated])

    if (isError) {
      return <Redirect to='/login' />
    }

    if (status === 'success') {
      return <ComposedComponent {...props} />
    }

    const message = status === 'loading' ? 'Loading the current user\'s information' : 'Fetching the current user\'s information'
    return <Loader message={message} />
  }

  return withRouter(Authenticated)
}

export default isAuthenticated