import React, { FC, useState, useEffect } from 'react'
import { appConfig } from '../../../assets/customizations/config'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'

import Eobs from '../../../components/FhirData/FhirResources/Eobs'
import Immunizations from '../../../components/FhirData/FhirResources/Immunizations'
import MemberProfile from '../../../components/FhirData/FhirResources/MemberProfile'
import Observations from '../../../components/FhirData/FhirResources/Observations'
import Coverage from '../../../components/FhirData/FhirResources/Coverage'
import Conditions from '../../../components/FhirData/FhirResources/Conditions'
import Allergies from '../../../components/FhirData/FhirResources/Allergies'
import Medications from '../../../components/FhirData/FhirResources/Medications'
import Procedure from '../../../components/FhirData/FhirResources/Procedure'
import CarePlans from '../../../components/FhirData/FhirResources/CarePlans'
import CareTeams from '../../../components/FhirData/FhirResources/CareTeams'
import isAuthenticated from '../../../routes/PrivateRoute'
import Encounters from '../../../components/FhirData/FhirResources/Encounters'


import { useRole } from '../../../hooks/authentication/useRole'
import useUser from '../../../hooks/authentication/useUser'
import { useGlobalState } from '../../../hooks/store/useGlobalState'
import useFhirResource from '../../../hooks/admin/useFhirResource'
import usePaginatedFhirResource from '../../../hooks/admin/usePaginatedFhirResource'
import { scrollToTop } from '../../../utils/helpers'

import '../../../App.scss'


interface RouteParams {
  page: string | undefined;
  detailId: string | undefined;
}

const getKeys = () => {
  const memberKeys = [
    { label: 'Member Profile', resource: 'Patient', show: true },
    { label: 'Coverage', resource: 'Coverage', show: true },
    { label: 'EOBs', resource: 'ExplanationOfBenefit', show: true },
  ]
  const clinicalKeys = appConfig.clinical_fhir_data.filter((data) => data.show)
  const allKeys = [...memberKeys, ...clinicalKeys]

  const keyResourceMap = allKeys.reduce((acc, { label, resource }) => {
    acc[label] = resource
    return acc
  }, {} as Record<string, string>)

  return { allKeys, keyResourceMap }
}

const Member: FC = () => {
  const fhirId = useGlobalState((state) => state.fhirId)

  const { allKeys: keys, keyResourceMap } = getKeys()
  const [key, setKey] = useState('Member Profile')

  const { page, detailId } = useParams<RouteParams>()
  const history = useHistory()

  const {
    data: eobData,
    refetch: getPatientEobs,
    isStale: eobsIsStale,
    isFetching: fetchingEobs,
    handleChangePage: handleChangeEobPage
  } = usePaginatedFhirResource(fhirId, 'ExplanationOfBenefit', 25)

  const {
    data: allergiesData,
    refetch: getPatientAllergy,
    isStale: allergyIsStale,
    isFetching: fetchingAllergy,
    handleChangePage: handleChangeAllergyPage
  } = usePaginatedFhirResource(fhirId, 'AllergyIntolerance', 25)

  const {
    data: conditionData,
    refetch: getPatientCondtions,
    isStale: condtionsIsStale,
    isFetching: fetchingCondtions,
    handleChangePage: handleChangeConditionPage
  } = usePaginatedFhirResource(fhirId, 'Condition', 25)

  const {
    data: encounterData,
    refetch: getPatientEncounters,
    isStale: encountersIsStale,
    isFetching: fetchingEncounters,
    handleChangePage: handleChangeEncounterPage
  } = usePaginatedFhirResource(fhirId, 'Encounter', 25)

  const {
    data: medicationData,
    refetch: getMedicationRequest,
    isStale: medicationIsStale,
    isFetching: fetchingMedication,
    handleChangePage: handleChangeMedication
  } = usePaginatedFhirResource(fhirId, 'MedicationRequest', 25)

  const {
    data: observationData,
    refetch: getObservations,
    isStale: observationIsStale,
    isFetching: fetchingObservation,
    handleChangePage: handleChangeObservation
  } = usePaginatedFhirResource(fhirId, 'Observation', 25)

  const {
    data: immunizationData,
    refetch: getImmunizations,
    isStale: immunizationIsStale,
    isFetching: fetchingImmunization,
    handleChangePage: handleChangeImmunization
  } = usePaginatedFhirResource(fhirId, 'Immunization', 25)


  const {
    data: procedureData,
    refetch: getProcedures,
    isStale: procedureIsStale,
    isFetching: fetchingProcedure,
    handleChangePage: handleChangeProcedure
  } = usePaginatedFhirResource(fhirId, 'Procedure', 25)



  const fhirResourcesConfig = [
    { key: 'patientData', resourceType: 'Patient' },
    { key: 'coverageData', resourceType: 'Coverage', count: 25 },
    { key: 'allergyData', resourceType: 'AllergyIntolerance', count: 25 },
  ]

  const fhirResources = fhirResourcesConfig.map(({ key, resourceType, count }) => {
    const { data, refetch, isStale, isFetching, isLoading } = useFhirResource(fhirId, resourceType, count)
    return { key, data, refetch, isStale, isFetching, isLoading }
  })

  const {
    patientData,
    coverageData,
    allergyData,
  } = fhirResources.reduce((acc, { key, data, refetch, isStale, isFetching, isLoading }) => {
    acc[key] = { data, refetch, isStale, isFetching, isLoading }
    return acc
  }, {} as Record<string, any>)

  const {
    data: carePlanData,
    refetch: getCarePlans,
    isStale: carePlansIsStale,
    isFetching: fetchingCarePlans,
    handleChangePage: handleChangeCarePlanPage
  } = usePaginatedFhirResource(fhirId, 'CarePlan', 25)

  const {
    data: careTeamData,
    refetch: getCareTeams,
    isStale: careTeamsIsStale,
    isFetching: fetchingCareTeams,
    handleChangePage: handleChangeCareTeamPage
  } = usePaginatedFhirResource(fhirId, 'CareTeam', 25)

  const { role } = useRole()
  const { data: user } = useUser()

  useEffect(() => {
    patientData.refetch()
    coverageData.refetch()
  }, [])

  useEffect(() => {
    if (key === 'AllergyIntolerance' && allergyIsStale) {
      getPatientAllergy()
    }

    if (key === 'Condition' && condtionsIsStale) {
      getPatientCondtions()
    }

    if (key === 'ExplanationOfBenefit' && eobsIsStale) {
      getPatientEobs()
    }

    if (key === 'Immunization' && immunizationIsStale) {
      getImmunizations()
    }

    if (key === 'Observation' && observationIsStale) {
      getObservations()
    }

    if (key === 'MedicationRequest' && medicationIsStale) {
      getMedicationRequest()
    }

    if (key === 'Procedure' && procedureIsStale) {
      getProcedures()
    }

    if (key === 'Encounter' && encountersIsStale) {
      getPatientEncounters()
    }

    if (key === 'CarePlan' && carePlansIsStale) {
      getCarePlans()
    }

    if (key === 'CareTeam' && careTeamsIsStale) {
      getCareTeams()
    }

  }, [key, patientData.isStale, eobsIsStale, coverageData.isStale, condtionsIsStale, detailId, allergyIsStale, procedureIsStale, medicationIsStale, immunizationIsStale, observationIsStale, encountersIsStale, carePlansIsStale, condtionsIsStale, careTeamsIsStale])

  useEffect(() => {
    if (page) {
      const key = keys.find((k) => k.resource === page)
      if (key) {
        setKey(key.resource)
      } else {
        history.replace('/404')
      }
    } else {
      setKey('Patient')
    }
    scrollToTop()
  }, [page])

  useEffect(() => {
    if (detailId) {
      if (key === 'Coverage') {
        history.replace('/404')
      }

      scrollToTop()
    }
  }, [key, detailId])

  const handleChangeKey = (k: string | null) => {
    const key = k || ''
    setKey(key)

    if (key) {
      const path = key === 'Patient' ? '/patient' : `/patient/${key}`
      history.push(path)
    }

    scrollToTop()
  }

  useEffect(() => {
    if (role === 'member') {
      if (fhirId !== user.memberId) {
        history.replace('/404')
      }
    }
  }, [fhirId, role])

  const renderFhirDataResource = (resource: string) => {
    switch (resource) {
      case 'Patient':
        return (
          <MemberProfile
            patientData={patientData?.data}
            loading={patientData?.isLoading || coverageData?.isLoading}
            coverageData={coverageData?.data}
          />
        )
      case 'Coverage':
        return (
          <Coverage
            patientData={patientData?.data}
            coverageData={coverageData?.data}
            isFetching={coverageData?.isFetching}
          />
        )
      case 'ExplanationOfBenefit':
        return (
          <Eobs
            patientData={patientData?.data}
            eobData={eobData}
            eobDetailId={detailId}
            page={page}
            isFetching={fetchingEobs}
            handleChangePage={handleChangeEobPage}
          />
        )
      case 'AllergyIntolerance':
        return (
          <Allergies
            patientData={patientData?.data}
            allergyData={allergiesData}
            isFetching={fetchingAllergy}
            allergyDetailId={detailId}
            page={page}
            handleChangePage = {handleChangeAllergyPage}
          />
        )
      case 'Condition':
        return (
          <Conditions
            conditionData={conditionData}
            isFetching={fetchingCondtions}
            patientData={patientData?.data}
            conditionDetailId={detailId}
            page={page}
            handleChangePage={handleChangeConditionPage}
          />
        )
      case 'Immunization':
        return (
          <Immunizations
            patientData={patientData?.data}
            immunizationData={immunizationData}
            immunizationDetailId={detailId}
            page={page}
            isFetching={fetchingImmunization}
            handleChangePage={handleChangeImmunization}
          />
        )
      case 'Observation':
        return (
          <Observations
            patientData={patientData?.data}
            observationData={observationData}
            observationDetailId={detailId}
            page={page}
            isFetching={fetchingObservation}
            handleChangePage={handleChangeObservation}
          />
        )
      case 'MedicationRequest':
        return (
          <Medications
            patientData={patientData?.data}
            page={page}
            isFetching={fetchingMedication}
            medicationData={medicationData}
            medicationDetailId={detailId}
            handleChangePage={handleChangeMedication}
          />
        )
      case 'Procedure':
        return (
          <Procedure
            patientData={patientData?.data}
            page={page}
            isFetching={fetchingProcedure}
            procedureData={procedureData}
            procedureDetailId={detailId}
            handleChangePage={handleChangeProcedure}
          />
        )
      case 'Encounter':
        return (
          <Encounters
            patientData={patientData?.data}
            page={page}
            isFetching={fetchingEncounters}
            encounterData={encounterData}
            encounterDetailId={detailId}
            handleChangePage={handleChangeEncounterPage}
          />
        )
      case 'CarePlan':
        return (
          <CarePlans
            patientData={patientData?.data}
            page={page}
            isFetching={fetchingCarePlans}
            carePlanData={carePlanData}
            carePlanDetailId={detailId}
            handleChangePage={handleChangeCarePlanPage}
          />
        )
      case 'CareTeam':
        return (
          <CareTeams
            patientData={patientData?.data}
            page={page}
            isFetching={fetchingCareTeams}
            careTeamData={careTeamData}
            careTeamDetailId={detailId}
            handleChangePage={handleChangeCareTeamPage}
          />
        )
      default:
        return <div>Not a supported Portal Resource</div>
    }
  }

  return (
    <main className='fhirData'>
      <Container>
        <Tab.Container activeKey={key} onSelect={handleChangeKey} transition={false}>
          <h2 className='headerText'>Member FHIR Data</h2>
          <Row>
            <Col sm={2}>
              <Nav variant='pills' className='flex-column'>
                {
                  keys.map((key) => (
                    <Nav.Item className='patient-pill' key={`nav-item-${key.resource}`}>
                      <Nav.Link eventKey={key.resource}>{key.label}</Nav.Link>
                    </Nav.Item>
                  ))
                }
              </Nav>
            </Col>
            <Col sm={10} className='mt-sm-0 mt-3'>
              <Tab.Content>
                {
                  keys.map((key) => (
                    <Tab.Pane eventKey={key.resource} key={`tab-pane-${key.resource}`}>
                      {
                        renderFhirDataResource(key.resource)
                      }
                    </Tab.Pane>
                  ))
                }
              </Tab.Content >
            </Col >
          </Row >
        </Tab.Container >
      </Container >
    </main >
  )
}

export default isAuthenticated(Member)
