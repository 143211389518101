import React, { FC } from 'react'
import isAuthenticated from '../../routes/PrivateRoute'
import MemberHome from '../MemberHome'
import Admin from '../Admin'
import { useRole } from '../../hooks/authentication/useRole'

const Home: FC = () => {
    const { role } = useRole()

    return (
        <>
            {role === 'member' && <MemberHome />}
            {role === 'admin' && <Admin />}
        </>
    )
}

export default isAuthenticated(Home)